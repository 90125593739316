<template>
  <div class="setting-area">
    <div class="Dealership">
      <!-- Dealership -->
      <div class="accordion" id="accordionExampleAT">
        <div class="card">
          <div class="card-header" id="headingOneAT">
            <h2 class="mb-0">
              <button
                class="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOneAT"
                aria-expanded="true"
                aria-controls="collapseOne31"
              >
                <div class="detais-title">
                  <h3>
                    <img
                      class="img-fluid arrow-down"
                      src="../../assets/img/arrow-down.svg"
                      alt=""
                    />Manage Notifications
                  </h3>
                </div>
              </button>
            </h2>
          </div>

          <div
            id="collapseOneAT"
            class="collapse show"
            aria-labelledby="headingOneAT"
            data-parent="#accordionExampleAT"
          >
            <div class="card-body">
              <!--automated -->
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-12">
                    <div class="alert-text">
                      <p></p>
                    </div>
                  </div>
                  <!-- single-alert -->
                  <div class="col-md-12">
                    <div class="alert-con">
                      <div class="table-footer">
                        <ul>
                          <li class="mint-box">
                            <div class="input-group">Tasks</div>
                          </li>

                          <li class="hide-alrt checka">
                            <label class="switch">
                              <input
                                type="checkbox"
                                true-value="1"
                                false-value="0"
                                v-model="notification.automatedtasks"
                                @change="
                                  onChangeHandeler('automatedtasks', $event)
                                "
                              />
                              <span class="slider round"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="alert-con">
                      <div class="table-footer">
                        <ul>
                          <li class="mint-box">
                            <div class="input-group">Alerts</div>
                          </li>

                          <li class="hide-alrt checka">
                            <label class="switch">
                              <input
                                type="checkbox"
                                true-value="1"
                                false-value="0"
                                v-model="notification.alert"
                                @change="onChangeHandeler('alert', $event)"
                              />
                              <span class="slider round"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="alert-con">
                      <div class="table-footer">
                        <ul>
                          <li class="mint-box">
                            <div class="input-group">Email</div>
                          </li>

                          <li class="hide-alrt checka">
                            <label class="switch">
                              <input
                                type="checkbox"
                                true-value="1"
                                false-value="0"
                                v-model="notification.email"
                                @change="onChangeHandeler('email', $event)"
                              />
                              <span class="slider round"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="alert-con">
                      <div class="table-footer">
                        <ul>
                          <li class="mint-box">
                            <div class="input-group">Facebook</div>
                          </li>

                          <li class="hide-alrt checka">
                            <label class="switch">
                              <input
                                type="checkbox"
                                v-model="notification.facebook"
                                @change="onChangeHandeler('facebook', $event)"
                                true-value="1"
                                false-value="0"
                              />
                              <span class="slider round"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="alert-con">
                      <div class="table-footer">
                        <ul>
                          <li class="mint-box">
                            <div class="input-group">Inner Circle</div>
                          </li>

                          <li class="hide-alrt checka">
                            <label class="switch">
                              <input
                                type="checkbox"
                                @change="
                                  onChangeHandeler('innercircle', $event)
                                "
                                v-model="notification.innercircle"
                                true-value="1"
                                false-value="0"
                              />
                              <span class="slider round"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      class="alert-con"
                      v-if="this.$storage.get('auth').user.user_type == 2"
                    >
                      <div class="table-footer">
                        <ul>
                          <li class="mint-box">
                            <div class="input-group">Lead Assign</div>
                          </li>

                          <li class="hide-alrt checka">
                            <label class="switch">
                              <input
                                type="checkbox"
                                @change="onChangeHandeler('leadassign', $event)"
                                v-model="notification.leadassign"
                                true-value="1"
                                false-value="0"
                              />
                              <span class="slider round"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      class="alert-con"
                      v-if="this.$storage.get('auth').user.user_type == 1"
                    >
                      <div class="table-footer">
                        <ul>
                          <li class="mint-box">
                            <div class="input-group">New Lead</div>
                          </li>

                          <li class="hide-alrt checka">
                            <label class="switch">
                              <input
                                type="checkbox"
                                @change="onChangeHandeler('NewLead', $event)"
                                v-model="notification.NewLead"
                                true-value="1"
                                false-value="0"
                              />
                              <span class="slider round"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="alert-con">
                      <div class="table-footer">
                        <ul>
                          <li class="mint-box">
                            <div class="input-group">SMS</div>
                          </li>
                          <li class="hide-alrt checka">
                            <label class="switch">
                              <input
                                type="checkbox"
                                v-model="notification.sms"
                                @change="onChangeHandeler('sms', $event)"
                                true-value="1"
                                false-value="0"
                              />
                              <span class="slider round"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import $ from "jquery";
import firebase from "firebase";
export default {
  name: "notifications",
  // mixins: [ VueDaval ],
  // vdRules: {
  //     setting_:  {
  //         duration:{required: true},unit:{required: true}
  //    }
  // },

  data() {
    return {
      notification: {
        email: "",
        sms: "",
        facebook: "",
        innercircle: "",
        leadassign: "",
        newlead: "",
        automatedtasks: "",
      },
    };
  },
  created() {},

  methods: {
    subscribeTokenToTopic(token, topic) {
      fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
        method: "POST",
        headers: new Headers({
          Authorization: `key=AAAAG69RFgo:APA91bFImVgPFU7wMbLfrI4cUU_TPFlkJQgn4MLrKxbAaEHMH-LO0HwECVaFvr1ApLXb7-3CNaGaQetr0fQ6_W7YOwAzwL8-DFCFYf6oxTf8hmEVZ16jmPN2KzrZ5eyKG3IB_zfnmpyL`,
        }),
      })
        .then((response) => {
          if (response.status < 200 || response.status >= 400) {
            console.log(response.status, response);
          }

          console.log(`"${topic}" is subscribed`);
        })
        .catch((error) => {
          console.error(error.result);
        });
      return true;
    },
    unsubscribeTokenToTopic(token, topic) {
      fetch(`https://iid.googleapis.com/iid/v1:batchRemove`, {
        method: "POST",
        headers: new Headers({
          Authorization: `key=AAAAG69RFgo:APA91bFImVgPFU7wMbLfrI4cUU_TPFlkJQgn4MLrKxbAaEHMH-LO0HwECVaFvr1ApLXb7-3CNaGaQetr0fQ6_W7YOwAzwL8-DFCFYf6oxTf8hmEVZ16jmPN2KzrZ5eyKG3IB_zfnmpyL`,
        }),
        body: JSON.stringify({
          to: "/topics/" + topic,
          registration_tokens: [token],
        }),
      })
        .then((response) => {
          if (response.status < 200 || response.status >= 400) {
            console.log(response.status, response);
          }

          console.log(`"${topic}" is unsubscribed`);
        })
        .catch((error) => {
          console.error(error.result);
        });
      return true;
    },
    onChangeHandeler(type, event) {
      let v = this;

      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then(function () {
          return messaging.getToken();
        })
        .then(function (token) {
          console.log(token);
          if (token) {
            v.notification.token = token;
            v.notification.plateform = "mob";
            axios
              .post("notification/update", v.notification, {
                headers: {
                  Authorization: "Bearer " + v.$storage.get("auth").token,
                },
              })
              .then((res) => {
                if (res.data.success) {
                  if (event.target.checked) {
                    v.subscribeTokenToTopic(
                      token,
                      type +
                        "_" +
                        v.$storage.get("auth").user.id +
                        "_" +
                        v.$storage.get("auth").user.dealer_id
                    );
                  } else {
                    v.unsubscribeTokenToTopic(
                      token,
                      type +
                        "_" +
                        v.$storage.get("auth").user.id +
                        "_" +
                        v.$storage.get("auth").user.dealer_id
                    );
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch(function (err) {
          console.log("User Chat Token Error" + err);
        });
    },
    getNogtificationSettings() {
      axios
        .get("get/notificationsettings", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.notification) {
              this.notification = res.data.notification;
            }

            console.log(this.notification);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getNogtificationSettings();
  },
};
</script>
