<template>
    <DashboardContainer>
        <Notifications/>
    </DashboardContainer>
</template>

<script>
import DashboardContainer from '../containers/DashboardContainer'
import Notifications from '../components/notification/Notification'

export default {
    name: 'notification',
    beforeCreate: function () {
        var auth = this.$storage.get('auth')
        if (!auth) {
            this.$router.push('/login')
        }else{
            if(!auth.token){
                this.$router.push('/login')      
            }
        }
    },
    components: {
        DashboardContainer,
        Notifications
    }
}
</script>